import React from "react";
import styles from "./styles.module.css";
import Logo from "../../assets/logonohoverwhite.png";
import LogoBilek from "../../assets/logolargeblack.png";
import Instagram from "../../assets/svg/Instagram";
import Facebook from "../../assets/svg/Facebook";
import Linkedin from "../../assets/svg/Linkedin";
import Youtube from "../../assets/svg/Youtube";
import { useWindowSize } from "@uidotdev/usehooks";
import { Link, useLocation } from "react-router-dom";

const socialMediaLinks = [
  {
    platform: "Instagram",
    icon: <Instagram />,
    link: "https://www.instagram.com/b.e.t.w.e.e.n.l.i.n.e.s",
  },
  {
    platform: "Facebook",
    icon: <Facebook />,
    link: "https://www.facebook.com/Betweenlines.Spacedesignstudio/",
  },
  {
    platform: "LinkedIn",
    icon: <Linkedin />,
    link: "https://www.linkedin.com/company/betweenlines/about/",
  },
  {
    platform: "Youtube",
    icon: <Youtube />,
    link: "https://www.youtube.com/@b.e.t.w.e.e.n.l.i.n.e.s",
  },
];
export default function Footer() {
  // const pathname = window.location.pathname;
  const { pathname } = useLocation();
  const size = useWindowSize();
  const conditionalStyles = {
    "/contact": {
      background: "#f8f8f8",
      color: "#2F2F2F",
      fill: "#2F2F2F",
      stroke: "#2F2F2F",
      border: "#2F2F2F",
    },
  };
  return (
    <footer className={styles.root} style={conditionalStyles[pathname]}>
      <div className={styles.footerUpper}>
        <div className={styles.firstChild}>
          <div className={styles.footerAdress}>
            <p className={styles.footerHeading}>Visit</p>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.google.com/maps/place/Betweenlines/@12.917454,77.619841,16z/data=!4m6!3m5!1s0x3bae14f0a891d35f:0x69151ddb0c683e44!8m2!3d12.917454!4d77.6198413!16s%2Fg%2F1tn0470k?hl=en-GB&entry=ttu'
              style={{ borderBottom: "2px solid", width: "fit-content", marginBottom: "0.625rem", display: "block" }}
            >
              Bangalore
            </a>
            <p>#24, 35th Main Rd, next to Alliance Business school, </p>
            <p>Dollar Scheme Colony, 1st Stage, BTM 1st Stage,</p>
            <p>Bengaluru, Karnataka 560068</p>
          </div>

          <div className={styles.socialIcons}>
            {socialMediaLinks.map((item, index) => (
              <a key={index} href={item.link} target='_blank' rel='noopener noreferrer'>
                {item.icon}
              </a>
            ))}
          </div>
          <p className={styles.madeBy}>
            Designed and developed by
            <span style={conditionalStyles[pathname]}>
              {" "}
              <a
                style={{
                  textDecoration: "underline",
                  // ...conditionalStyles[pathname],
                }}
                href='https://bygowri.framer.website/?fbclid=PAZXh0bgNhZW0CMTEAAaZX-wccq0vlu3FDMA_sUSqmAv5YVvGMjFu3lHYOLqIrP45TQhLk233WIKM_aem_99NGgIZBVMJri_FpPr7-dA'
                target='_blank'
              >
                Gowri,
              </a>{" "}
            </span>{" "}
            <span style={conditionalStyles[pathname]}>
              Nimish and{" "}
              <a
                style={{
                  textDecoration: "underline",
                }}
                href='https://iter8.netlify.app/'
                target='_blank'
              >
                Ankit
              </a>{" "}
            </span>
          </p>
        </div>
        <div className={styles.thirdChild}>
          <div>
            <p className={styles.footerHeading}>Careers and Enquiries</p>
            <p className={styles.footerAdress}>contact@betweenlines.in</p>
          </div>

          {pathname !== "/contact" && (
            <Link to={"/contact"}>
              <button className={styles.thirdChild_button}>Contact Us</button>
            </Link>
          )}
        </div>
      </div>

      {pathname !== "/contact" && <div className={styles.horizontalLine}></div>}
      {pathname === "/contact" && <div className={styles.horizontalLineBlack}></div>}

      <div className={styles.footerLower}>
        {pathname !== "/contact" && <img src={Logo} alt='Logo' className='img-noshadow' />}
        {pathname === "/contact" && <img src={LogoBilek} alt='Logo' className='img-noshadow' />}
      </div>
    </footer>
  );
}
